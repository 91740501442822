import HomePage from "../home/index";
import Splash from "../page/Splash";
import CompareForm from "../compare/index";
import CompareList from "../compare/CompareList";
import Page from "../page/index";
import OperatorPage from "../page/OperatorPage";
import Resource from "../page/Resource";
import Video from "../page/Video";
import Calculators from "../page/Calculators";
import OvertimeCalculator from "../overtime/index";
import BudgetCalculator from "../budget/index";
import Faq from "../page/Faq";
import NotFound from "../page/NotFound";
import Settings from "../page/Settings";

const pageRoutes = [
  { path: '/compare', exact: true, component: CompareForm, title: 'Compare' },
  { path: '/compare/:currencyFrom/:countryFrom/:countryTo/:amount', exact: true, component: CompareList, title: 'Compare Results' },
  { path: '/page/:slug', component: Page, title: 'Page' },
  { path: '/compare/:slug', component: OperatorPage, title: 'Operator Page' },
  { path: '/video', exact: true, component: Video, title: 'Videos' },
  { path: '/resource', exact: true, component: Resource, title: 'Resources' },
  { path: '/calculator', exact: true, component: Calculators, title: 'Calculator' },
  { path: '/calculator/overtime', exact: true, component: OvertimeCalculator, title: 'Overtime Calculator' },
  { path: '/calculator/budget', exact: true, component: BudgetCalculator, title: 'Budget Calculator' },
  { path: '/help', exact: true, component: Faq, title: 'Help Center' },
  { path: '/settings', exact: true, component: Settings, title: 'Settings' },
  { path: '/home', exact: true, component: HomePage, title: 'Home' },
  { path: '/', exact: true, component: Splash, title: 'Splash' },
  { component: NotFound, title: '404 Not Found' },
];

export default pageRoutes;
